@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
/* main container */
html, body {
  height: 100vh;
  margin: 0em;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Prevent scrolling */
  margin: 0;
  padding: 0;
}
/*@media only screen and (max-width: 600px) {
  /* Styles for phones */
  /*.container {
      text-align: center;
      width: 100%;
      padding: 0;
      margin: 0;
  }
}*/

/* Rotate content for landscape orientation to force it to look like portrait */
/*@media screen and (orientation: landscape) {
  body {
    transform: rotate(90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    padding: 0;
    margin: 0;
  }
}*/
/*
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
/*  width: 100vw;
  margin: 0em;
}/* reset the box sizing at the start for Firefox compatibility */
* {
  box-sizing: border-box;
  margin: 0em;
  padding: 0em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

td {
  border-top: 0.1em solid #003D7C;
  border-collapse: collapse;
}

.header {
  background-color: #003D7C;
  height: 4.5em;
  position: absolute;
  width: 100%;
  top: 0em;
}

.header-howto:hover {
  background-color: rgba(0, 61, 124, 0.25); /* 50% opacity */;
  color: rgba(239, 124, 0, 1); /* 50% opacity */;
  transition: background-color 0.3s ease;
}

.footer {
  position: fixed;
  background-color: #003D7C;
  height: 1.75em;
  text-align: right;
  width: 100%;
  bottom: 0em;
}

.footer-link {
  color: #EF7C00;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.menufab {
  float: left;
  top: 1em;
  left: 1em;
}

.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop {
  background-color: rgba(255, 255, 255, 0.2) !important;
  -webkit-backdrop-filter: blur(0.1em); 
  backdrop-filter: blur(0.1em);
}

.css-169ycje-MuiPaper-root-MuiSnackbarContent-root {
  min-width: 2em !important;
  max-width: 2em !important;
}

h1,
h2,
h3,
h4,
h5 {
  color: #EF7C00;
}

.allcards {
  margin: 0em;
  transform: scale(1);
}
  h1,
  .menufab,
  .header-howto {
    transform: scale(1);
  }

.memory-game-wrapper {
  display: grid;
  overflow: hidden;
  justify-content: center;
  padding: 0% 0% 0% 0%;/* Set padding for card grid box */
  margin: 0% 0%; 
  width: 100vw; /* Width of card grid */
  height: 100hv; /* height of card grid */
  margin-top: 0%;
  padding-top: 0%;
}

.CardsContainer {
  display: flex;
  overflow: hidden;
  /*grid-template-columns: repeat(3, 10vw); */
  /*grid-template-rows: repeat(4, 10%);*/
  /*gap: 0%; *//* Adjust the gap between the grid items as needed */
  width: 100%; /* Adjust as needed */
  height: 85%; /* Adjust as needed */
  perspective: 100%;
}

.card {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.2em solid #ffffff; /* Optional: for better visual separation */
  font-size: 10pt;
  transform-style: preserve-3d;
  transition: transform 0.6s;

}

.flip {
  transform: rotate3d(0, 1, 0, 180deg);
}

.card-face {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
  background-color: #EF7C00;
}

.back-face {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.front-face {
  transform: rotate3d(0, 1, 0, 180deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
  display: flex;
  float: center;
  min-width: 100% !important;
  max-width: 150% !important;
}

.header-info {
  float: center;
  display: flex;
}
.header-howto {
  float: center;
  display: flex;
}

.modalBody {
  padding: 0.5em 1em;
  font-size: 11pt;
  text-align: left;
}

.css-19l5h75-MuiSlider-markLabel, .css-xxsb21-MuiSlider-markLabel {
  -webkit-user-select: none;
  user-select: none;
}

.center-divider {
  display: flex;
  width: 60%; /* Adjust width as needed */
  margin: 1em auto; /* Centers the divider horizontally */
  height: 0.15em; /* Height of the divider */
  background-color:  #EF7C00; /* Color of the divider */
  border: none;
}

.line-spacing-0-5 {
  line-height: 0.5;
}

.line-spacing-1-5 {
  line-height: 1.5;
}
